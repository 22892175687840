<template>
  <div class="newscenter">
    <div class="bg">
      <img
        src="@imgs/xwzxbg.png"
        width="194px"
        height="93px"
        alt=""
        class="cpzx"
      />
    </div>
    <div class="newscontent">
      <div class="contentbox">
        <div class="left">
          <div class="newslist">
            <div class="newsitem" v-for="item in newsList" :key="item.id">
              <div class="title" @click="toDetails(item.id)">四川将实行行政村快递全覆盖</div>
              <div class="newsvalue">
                <div>
                  本报四川6月3日电 （记者邵玉姿）为有效解决农村快递不按址投递问题，彻底解决农村末端服务违规收费问题，近日，四川邮政管理局印发《关于加快推进“快递进村”工作的指导意见》，提出到2021年8月份行政村快递服务基本实现全覆盖。
                </div>
                <div>
					《意见》明确了三项重点任务。一是因地制宜推进“快递进村”，在全面摸清辖区进村底数的基础上，根据各地实际细化实化“快递进村”推进方案，强力推进企业合作进村。二是开展农村快递专项整治活动，依法依规审查企业农村快递服务能力，切实推动农村快递服务规范化建设，保障农村网点稳定，夯实农村寄递安全基础。三是压实“快递进村”统计数据，采取多种形式开展数据核查，接受社会群众监督，优化数据报送渠道，确保采集的数据资源真实、准确、完整和及时。
                </div>
              </div>
              <span>2021-12-01</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="search">
            <input type="text" placeholder="搜索" />
            <img src="@imgs/search.png" width="17px" height="17px" alt="" />
          </div>
          <div class="recommend">
            <div class="wntj">为你推荐</div>
            <div class="gd">
              更多<img src="@imgs/gt.png" width="6px" height="11px" alt="" />
            </div>
          </div>
          <div class="recommendlist">
            <div class="item" v-for="item in newsList" :key="item.id">
              <div class="square"></div>
              <div class="recomedtitle">
                四川将实行行政村快递全覆盖
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [{ id: 0 }, { id: 1 }],
    };
  },
  methods:{
    toDetails(id){
      this.$router.push({path:'/newsdetails',query:{id:id}})
    }
  }
};
</script>

<style lang="scss" scoped>
.newscenter {

  .newscontent {
    width: 100%;
    height: 1200px;
    background: #f1f1f1;
    padding-top: 30px;
    .contentbox {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      // height: 1073px;
      justify-content: space-between;
      .left {
        width: 817.9px;
        box-sizing: border-box;
        padding: 35px;
        background: #ffffff;
        border-radius: 8px;
        .newslist {
          text-align: left;
          .newsitem {
            font-family: Microsoft YaHei;
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 28px;
            margin-bottom: 30px;
            &:nth-last-child(1) {
              border: none;
            }
            // &:nth-of-type(1) {
            //   .title {
            //     color: #fe2a1e;
            //   }
            // }
            .title {
              font-size: 24px;
              &:hover {
                color: #fe2a1e;
                cursor: pointer;
              }
            }
            .newsvalue {
              margin-top: 23px;
              margin-bottom: 17px;
              font-size: 18px;
              color: #666565;
              div {
                line-height: 30px;
              }
            }
            span {
              font-size: 18px;
              font-family: DengXian;
              font-weight: bold;
              color: #999999;
            }
          }
        }
      }
      .right {
        width: 353px;
        border-radius: 8px;
        text-align: left;
        padding: 33px;
        height: 478px;
        box-sizing: border-box;
        background: #ffffff;
        .search {
          border: 1px solid #cccccc;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 11px 15px 8px 15px;
          input {
            flex: 1;
            font-size: 17px;
            outline: none;
            border: none;
          }
        }
        .recommend {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          margin-top: 28px;
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 9px;
          .wntj {
            font-size: 20px;
            font-family: Microsoft YaHei;
            color: #333333;
          }
          .gd {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: DengXian;
            color: #fe2a1e;
            img {
              margin-left: 5px;
            }
          }
        }
        .recommendlist {
          .item {
            padding-bottom: 15px;
            border-bottom: 1px dotted #cccccc;
            margin-top: 17px;
            display: flex;
            // &:nth-last-child(1){
            //   .square{
            //     background: #FE2A1E;
            //   }
            //   .recomedtitle{
            //     color: #FE2A1E;
            //   }
            // }
            &:hover {
              .square {
                background: #fe2a1e;
              }
              .recomedtitle {
                color: #fe2a1e;
              }
              cursor: pointer;
            }
            .square {
              width: 8px;
              height: 6px;
              background: #999999;
              margin-top: 7px;
              margin-right: 5px;
            }
            .recomedtitle {
              color: #666565;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666565;
            }
          }
        }
      }
    }
  }
}
</style>